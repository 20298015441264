import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {useSelector} from "react-redux";
import {isIOS} from "react-device-detect";

import PropTypes from "prop-types";
import React from "react";
import {Tooltip} from 'react-tooltip'
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function NearbyHighlights(props) {
    const {onVenueClick, selectedVenueId, showsTooltip} = props;
    const videoFormat = isIOS ? "h264" : "webm";

    const venues = useSelector((state) => state.venues);

    const tapAgainTooltipProps = {
        "data-tooltip-id": "tap-again-tip",
        "data-tooltip-place": "top",
    };

    if (!venues || venues.length === 0) {
        return <p className="py-4 px-2">No venues in this area yet, try zooming out or moving the map.</p>
    }

    return (
        <div className="grid grid-flow-col overflow-x-auto px-2 h-full" id="nearbyhighlights">
            {venues.map((f) => {
                let selected = selectedVenueId === f.id;
                let extraProps = selected ? tapAgainTooltipProps : {};

                return (
                    <div
                        key={f.id}
                        className={`${selected ? "shadow-[0px_0px_3px_6px_rgba(204,85,0,0.8)] Xborder Xborder-black" : "Xborder Xborder-gray-400"} cursor-pointer bg-slate-500 w-[80px] text-xs overflow-clip rounded-lg relative mr-5 mt-3 mb-1`}
                        onClick={() => onVenueClick(f)}
                        id={`venue_${f.id}`}
                        {...extraProps}
                    >
                        <LazyLoadImage
                            effect="blur"
                            src={f.thumbnails?.img}
                        />
                        {selected && (
                            <div className="z-40 top-0 absolute">
                                <video autoPlay={true} playsInline={true} controls={false} loop={true}>
                                    {videoFormat === "webm" && (
                                        <source src={f.thumbnails?.webm} type="video/webm"/>
                                    )}
                                    {videoFormat === "h264" && (
                                        <source src={f.thumbnails?.h264} type="video/mp4"/>
                                    )}
                                </video>
                            </div>
                        )}
                        <div
                            className="align-bottom w-full right-0 bg-black/70 absolute z-40 bottom-0 text-right pr-1 text-white h-10">
                            {f.name}
                        </div>
                    </div>
                );
            })}
            <Tooltip id="tap-again-tip" isOpen={showsTooltip}
                     style={{backgroundColor: "rgb(255, 153, 0)", color: "black"}}
                     clickable={true}
                     offset={15}
            >
                <div className="flex flex-col space-y-2 justify-center items-center text-center">
                    <p>Tap video to zoom or <br/>
                        get directions</p>
                </div>
            </Tooltip>
        </div>
    )
        ;
}

// 👇️ define prop types for the component
NearbyHighlights.propTypes = {
    venues: PropTypes.array,
    onVenueClick: PropTypes.func
};
