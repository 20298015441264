import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga4';
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import HomePage from "./routes/home-page";
import AppConfig from "./store/config";
import PrivacyPolicyPage from "./routes/privacy-policy-page";
import TermsAndConditionsPage from "./routes/terms-page";
import ContentPageRoot from "./routes/contentPageRoot";
import {QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query'
import 'react-tooltip/dist/react-tooltip.css';

Sentry.init({
    dsn: "https://a33bf6157d0cd295ef05105d57968bc2@o4507073858568192.ingest.us.sentry.io/4507077291671552",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/nomnommap\.azurewebsites\.net\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient()

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {index: true, element: <HomePage/>},
            {index: true, path: "venue/:venueId", element: <HomePage/>},
            // for future variation where we do server side render or dedicated page:
            // {
            //   path: "venue/:venueId",
            //   element: <VenueDetailPage />,
            // },
        ],
    },
    {
        path: "/about",
        element: <ContentPageRoot/>,
        errorElement: <ErrorPage/>,
        children: [
            {path: "privacy", element: <PrivacyPolicyPage/>},
            {path: "terms", element: <TermsAndConditionsPage/>},
        ],
    },
]);

ReactGA.initialize(AppConfig.GOOGLE_ANALYTICS_ID)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router}/>
        </QueryClientProvider>
    </React.StrictMode>
);
