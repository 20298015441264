// TODO: Need to do someting about where config is stored, eg API keys. Pulls in from JSON but this risks deploying
//       the wrong file/comitting dev code
import {configureStore, createSlice} from "@reduxjs/toolkit";
import L from "leaflet";

import AppConfig from "./config";
import {NomnomApiUrlBuilder} from "../api/nomnomApiUrlBuilder";

const initialState = {
    categoryId: null,
    categoryName: null,
    reviewerId: null,
    reviewers: [],
    venues: [], // current venues to display (subset of cachedVenues)
    bounds: {}, // geojson {north, south, east, west}. current bounds (subset of cachedBounds)
    // cached bounds & venues may exceed venues/bounds properties - provides optimized pan/zoom
    cachedBounds: {}, // geojson. north, south, east, west
    cachedVenues: [], // all venues within cachedBounds
    
    // ? favourite influencer?
    // ? loading state?
    selectedLocation: null, //{name, lat, lng}
    error: null,  // .message, .details
    isLoading: false
};

// no idea why this can't be part of the appSlice but it's annoying
const _refilter = (state) => {   
    // only display venues within the desired bounds
    let bounds = L.latLngBounds(L.latLng(state.bounds.south, state.bounds.west), L.latLng(state.bounds.north, state.bounds.east));
    state.venues = state.cachedVenues.filter(venue => bounds.contains(L.latLng(venue.location.lat, venue.location.lng)));
    
    // apply category filter
    if (state.categoryId) {
        state.venues = state.venues.filter(
            (venue) => (venue.categoryIds ?? []).includes(state.categoryId)
        );
    }
};

const appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        setCachedBounds(state, action) {
            state.cachedVenues = action.payload.venues;
            state.cachedBounds = action.payload.bounds;
            state.bounds = action.payload.bounds;
            _refilter(state);
        },
        setBounds(state, action) {
            // really just a filter over cachedVenues
            state.bounds = action.payload.bounds;
            _refilter(state);
        },
        setCategory(state, action) {
            state.categoryId = action.payload.id;
            state.categoryName = action.payload.name;
            _refilter(state);
        },
        setReviewer(state, action) {
            state.reviewerId = action.payload;
            _refilter(state);
        },
        setError(state, action) {
            if (action.payload == null) {
                state.error = {
                    message: "Unexpected error",
                    details: null
                };
            } else {
                state.error = {
                    message: action.payload.message,
                    details: action.payload.details
                }
            }
        },
        clearError(state) {
            state.error = null;
        },
        setLoading(state, action){
            state.isLoading = action.payload ?? false;
        },
        setLocation(state, action){
            // TODO: should this automatically reload whatever is in the current map bounds?
            
            if (action.payload == null){
                state.selectedLocation = null;
            } else {
                state.selectedLocation = {
                    id: action.payload.id,
                    name: action.payload.name, 
                    lat: action.payload.lat, 
                    lng: action.payload.lng};
            }  
        }
    },
});

export const getVenuesInBounds = (bounds, getState) => {
    return async (dispatch, getState) => {
        console.log("getVenuesInBounds is running");
        
        dispatch(appActions.setLoading(true));
        dispatch(appActions.clearError());
        const fetchData = async () => {
            const response = await fetch(
                NomnomApiUrlBuilder.GetVenuesInBounds(bounds.north, bounds.south, bounds.east, bounds.west)
            );

            if (!response.ok) throw new Error("Could not load venue data");

            const data = await response.json();
            return data;
        };

        try {
            // if bounds within previously fetches then just filter visible data, no API hit 
            let cachedBoundsState = getState().cachedBounds;
            if (cachedBoundsState && cachedBoundsState.north) {
                let cachedBounds = L.latLngBounds(L.latLng(cachedBoundsState.south, cachedBoundsState.west), L.latLng(cachedBoundsState.north, cachedBoundsState.east));
                let newBounds = L.latLngBounds(L.latLng(bounds.south, bounds.west), L.latLng(bounds.north, bounds.east));
                let isWithinBounds = cachedBounds.contains(newBounds);
                
                // already within bounds, no need for server side render
                if (isWithinBounds){
                    console.log("Already within cached bounds. Not fetching");
                    dispatch(appActions.setBounds({bounds: bounds}));
                    return;
                }
            }

            // new bounds are outside previous bounds - hit server for data
            const venues = await fetchData();
            dispatch(appActions.setCachedBounds({venues: venues, bounds: bounds})); 
        } catch (error) {
            // todo handle this...
            console.log("error: " + error);
            dispatch(appActions.setError({message: 'Unable to load venues in selected area', details: error.message}));
        } finally {
            dispatch(appActions.setLoading(false));
        }
    };
};

const store = configureStore({
    reducer: appSlice.reducer,
});

export const appActions = appSlice.actions;

export default store;
