import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faHeart, faListUl, faLocationDot, faMap, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import React, {useState} from "react";

interface FooterProps {
}

const Footer: React.FC<FooterProps> = ({...props}) => {

    return (
        <footer className="px-4 py-4 bg-gray-100 text-xs text-gray-600">
            <div className="flex justify-between">
                <p className="mr-8 group hover:text-amber-600 border-b-2 border-white hover:border-amber-600"><Link
                    to="/about/privacy">Privacy Policy</Link></p>
                <p className="mr-8 hover:text-amber-600 border-b-2  border-white hover:border-amber-600 "><Link
                    to="/about/terms">Terms</Link></p>
                <p className="ml-auto">Copyright &copy; {new Date().getFullYear()} <a
                    className="group hover:text-amber-600 border-b-2 border-amber-600"
                    href="https://mogumedia.com" target="_blank">Mogu Media</a></p>
            </div>
        </footer>
    )
}

export default Footer;