import {Outlet, useRouteError} from "react-router-dom";
import {useEffect} from "react";
import ReactGA from "react-ga4";
import {Provider} from "react-redux";
import store from "../store";
import TopNavMenu from "../components/TopNavMenu";
import Footer from "../components/Footer";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    useEffect(() => {
        document.title = "Error";

        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search
        });
    }, []);

    return (
        <>
            <div className="flex flex-col h-dvh">
                <TopNavMenu/>
                <div className="px-4 flex-1">
                    <h1 className="font-bold text-lg">Oops!</h1>
                    <div className="space-y-3">
                        <p>Sorry something went wrong.</p>
                        <p>You can try going back, or visiting the <a href="/" className="underline text-blue-700">home
                            page</a> or please <a className="underline text-blue-700" href='https://mogumedia.com/contact-us/'>contact
                            us</a> if this keeps happening</p>
                        
                        <p>
                            Error details: <i>{error.statusText || error.message}</i>
                        </p>
                    </div>
                </div>
                <div className="my-auto flex-0">
                    <Footer/>
                </div>
            </div>
        </>
    );
}