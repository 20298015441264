/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import {useSelector} from "react-redux";
import {TikTokEmbed} from "react-social-media-embed";

import {faInstagram, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {faCar, faClose, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "@headlessui/react";
import useApi from "../api/useApiHook";
import {NomnomApiUrlBuilder} from "../api/nomnomApiUrlBuilder";
import React, {useEffect} from "react";
import LoadingSpinner from "./LoadingSpinner";
import ReactGA from "react-ga4";
import {useQuery} from "@tanstack/react-query";

function MarkerDetail(props) {
    const {onClose, venueId} = props;

    const {isPending, error, data: venue} = useQuery({
        queryKey: ['venue_details_' + (venueId ?? "null")],
        queryFn: () =>
            fetch(NomnomApiUrlBuilder.GetVenue(venueId)).then((res) =>
                res.json(),
            ),
    });

    const showDirections = () => {
        ReactGA.event("get_directions", {
            item_id: venue.id,
            item_name: venue.name,
            featured: venue.featured ?? false
        });
    }
    const visitSocial = (channel, handle) => {
        ReactGA.event("visit_social", {
            item_id: venue.id,
            item_name: venue.name,
            channel: channel,
            featured: venue.featured ?? false
        });
    }
    const shareLink = async () => {
        try {
            let url = `https://nomnommap.com/venue/${venue.id}?utm_source=nomweb&utm_medium=share`;
            let method = null;
            let shareData = {
                title: "Nom Nom Map Venue",
                text: `Check out ${venue.name}, ${venue.suburb}. ${url}`
            };

            if (window.navigator.canShare && window.navigator.canShare(shareData)) {
                method = "native";
                await window.navigator.share(shareData);
            } else {
                // fallback to dumb SMS option which might or might not work?
                method = "sms";
                window.location = "sms://?&body=" + encodeURIComponent(shareData.text);
            }

            ReactGA.event("share", {
                method: method,
                content_type: 'venue',
                item_id: venue.id,
                item_name: venue.name,
                featured: venue.featured ?? false
            });
        } catch (e) {
            if (e instanceof DOMException) {
                if (e.name === "AbortError" || e.code === DOMException.ABORT_ERR) {
                    // non event. User cancelled sharing
                } else if (e.name === "NotAllowedError")
                    alert("Sharing is not allowed by your device");
                else
                    alert(`Sharing failed. ${e.message} (${e.name})`);
            } else
                alert("Sharing failed. " + e);
        }
    }

    const computeVideoTopOffset = () => {
        // minimum height on-screen required to trigger tiktok's video autoplay 
        const minAutoplayHeight = 605;
        const availableSpace = window.innerHeight;
        const infobarHeight = 75;

        let topOffset = 0;
        if (availableSpace < minAutoplayHeight) {
            // impossible to squeze video on screen, push it below our header bar so it fully displays
            // (but user will have to manually click play)
            topOffset = infobarHeight;
        } else {
            if (infobarHeight + minAutoplayHeight > availableSpace) {
                // not enough height to stack infobar + video under. Slide video under header a bit
                let tooBigBy = (infobarHeight + minAutoplayHeight) - availableSpace;
                topOffset = infobarHeight - tooBigBy;
            } else {
                topOffset = infobarHeight;
            }
        }

        return topOffset;
    }

    // TODO: find the featured reviewer in the list, if there is one, and select it instead of [0]
    // TODO: currently only works for tiktok, so filtering out insta...
    const review = venue?.reviews.filter((rvw) => rvw.type == "tiktok")[0];

    const googleMapUrl =
        "https://www.google.com/maps/search/?api=1&query=" +
        encodeURIComponent(venue?.name) +
        ", " +
        encodeURIComponent(venue?.address);

    return (
        <>
            <Dialog
                className="relative z-50"
                open={true}
                onClose={onClose}
            >
                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <div className="fixed inset-0 bg-black/30" aria-hidden="true"/>

                {/* Full-screen scrollable container */}
                <div className="fixed inset-0 w-screen Xoverflow-y-auto">
                    {/* Container to center the panel */}
                    <div className="flex m-auto h-full items-center justify-center Xp-4">
                        {/* The actual dialog panel  */}
                        <Dialog.Panel
                            className="mx-auto w-full sm:w-[32rem] sm:rounded-xl bg-white h-full sm:h-5/6 overflow-y-auto relative">
                            <div
                                className="z-50 absolute top-0 bg-gray-100 w-full pt-2 pb-3 px-3 pr-4 sm:rounded-t border-b">
                                <div className="flex flex-row">
                                    <h1 className="flex-grow text-lg">
                                        <span className="font-bold">{venue?.name ?? "Loading"}</span>
                                    </h1>
                                    <a onClick={onClose} className="cursor-pointer">
                                        <FontAwesomeIcon size="xl" icon={faClose}/>
                                    </a>
                                </div>
                                {isPending && (
                                    <div role="status" className="w-full animate-pulse">
                                        <div className="flex space-x-3 mt-2">
                                            <div
                                                className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-1/4"></div>
                                            <div
                                                className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-1/4 "></div>
                                            <div
                                                className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-1/4"></div>
                                        </div>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                                {!error && !isPending &&
                                    <div className="text-gray-600">
                                        <ul className="mt-1 flex content-center items-start text-sm space-x-2">
                                            <li>
                                                <a
                                                    className="text-blue-500 underline nowrap"
                                                    href={googleMapUrl}
                                                    onClick={showDirections}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <FontAwesomeIcon
                                                        className="inline-block mr-1"
                                                        icon={faCar}
                                                    />
                                                    Directions
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="text-blue-500 underline"
                                                    href="#"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        shareLink();
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className="inline-block mr-1"
                                                        icon={faPaperPlane}
                                                    />
                                                    Share
                                                </a>
                                            </li>
                                            {venue.socials.map((social) => {
                                                let socialUrl =
                                                    social.type === "tiktok"
                                                        ? `https://www.tiktok.com/@${social.handle}`
                                                        : `https://instagram.com/${social.handle}`;
                                                let socialIcon =
                                                    social.type === "tiktok" ? faTiktok : faInstagram;

                                                return (
                                                    <li className="ml-4" key={social.type}>
                                                        <a
                                                            href={socialUrl}
                                                            onClick={() => visitSocial(social.type, social.handle)}
                                                            className="text-blue-500 underline"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="inline-block mr-1"
                                                                icon={socialIcon}
                                                            />
                                                            {social.handle}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                }
                            </div>
                            {!error && !isPending && (
                                <div className="flex justify-center" style={{
                                    marginTop: `${computeVideoTopOffset()}px`
                                }}>
                                    <TikTokEmbed url={review.url} width={325}/>
                                </div>
                            )}
                            {isPending && (
                                <div role="status" className="w-full animate-pulse mt-24 flex justify-center mb-20">
                                    <div className="h-96 bg-gray-300 rounded-xl dark:bg-gray-700 w-5/6">

                                    </div>
                                </div>

                            )}
                        </Dialog.Panel>
                    </div>
                </div>

            </Dialog>
        </>
    );
}

export default MarkerDetail;
