/// <reference types="vite/client" />

export default class AppConfig {
    static API_URL = import.meta.env.VITE_NOMNOM_API_URL; 
    
    // NB: maps key imported from meta.env in index.html too
    static GOOGLE_MAPS_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY; 
    static GOOGLE_ANALYTICS_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;
    
    static CurrentLocationId = "current_location";
    static CurrentLocationName = "Current location";
};


