import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {faClose, faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "@headlessui/react";
import PropTypes from "prop-types";

import {appActions} from "../store";
import ReactGA from "react-ga4";
import {useQuery} from "@tanstack/react-query";
import {NomnomApiUrlBuilder} from "../api/nomnomApiUrlBuilder";
import LoadingSpinner from "./LoadingSpinner";

export default function CategorySearchDialog(props) {
    const {onClose} = props;

    const {isPending, error, data: categories} = useQuery({
        queryKey: ['categories'],
        queryFn: () =>
            fetch(NomnomApiUrlBuilder.GetCategories()).then((res) =>
                res.json(),
              ),
        gcTime: 1000 * 60 * 10 // 10 minutes cache 
    })

    const dispatch = useDispatch();
    const selectedCategoryId = useSelector((state) => state.categoryId);

    const onCategoryChanged = (categoryId) => {
        let categoryName = !categoryId ? null
            : categories.find((el) => el.id === categoryId).name;
        
        ReactGA.event("filter", {
            filter_type: "categories",
            item_id: categoryId ?? "ALL",
            item_name: categoryName ?? "ALL"
        });

        dispatch(appActions.setCategory({id: categoryId, name: categoryName}));
        onClose();
    };

    return (
        <Dialog open={true} onClose={onClose} className="relative z-50">
            <div className="fixed inset-0 bg-white md:bg-black/60" aria-hidden="true"/>

            {/* Full-screen scrollable container */}
            <div className="overflow-y-scroll fixed inset-0">
                {/* Container to center the panel */}
                <div className="flex min-h-full sm:items-center sm:justify-center">
                    <Dialog.Panel className="w-full sm:w-[32rem] sm:h-4/6 mx-auto sm:rounded bg-white p-3">
                        <Dialog.Title>
                            <div className="flex flex-row">
                                <h1 className="flex-grow text-lg">
                                    <span className="font-bold">Food type</span>
                                </h1>
                                <a onClick={onClose}>
                                    <FontAwesomeIcon size="xl" icon={faClose}/>
                                </a>
                            </div>
                        </Dialog.Title>
                        <Dialog.Description>
                            <div className="pb-4 text-gray-600">
                                Choose a category to filter the map.
                            </div>
                        </Dialog.Description>
                        {error && (
                            <div className="text-sm text-red-500">
                                <p>Error loading categories. {error.toString()}</p>
                                <p>If this keeps happening, please <a className="underline" href="mailto:contact@mogumedia.com">let us know</a></p>
                            </div>
                                )
                        }
                        {isPending && 
                            <LoadingSpinner size="large" />
                        }
                        {!error && !isPending &&
                            <ol>
                                <li
                                    className={`border-b p-2 cursor-pointer ${
                                        selectedCategoryId === null ? "font-bold" : ""
                                    }`}
                                    onClick={() => onCategoryChanged(null)}
                                >
                                    {selectedCategoryId === null && (
                                        <FontAwesomeIcon size="lg" icon={faCheck} className="mr-2" />
                                    )}
                                    All food types
                                </li>
                                {categories.map((cat) => (
                                    <li
                                        className={`border-b p-2 cursor-pointer ${
                                            selectedCategoryId === cat.id ? "font-bold" : ""
                                        }`}
                                        key={cat.id}
                                        onClick={() => onCategoryChanged(cat.id)}
                                    >
                                        {selectedCategoryId === cat.id && (
                                            <FontAwesomeIcon size="lg" icon={faCheck} className="mr-2" />
                                        )}    
                                        {cat.name}
                                    </li>
                                ))}
                            </ol>
                        }
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
}

// 👇️ define prop types for the component
CategorySearchDialog.propTypes = {
    onClose: PropTypes.func,
};
