import React, {useEffect} from "react";
import ReactGA from "react-ga4";

function TermsAndConditionsPage() {

    useEffect(() => {
        document.title = "Nom Nom Map Terms & Conditions";

        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search
        });
    }, []);

    return (
        <>
                <h1 className="font-bold text-lg mb-4">Terms and Conditions for Nom Nom Map</h1>
                <div className="space-y-4 mb-4">
                    <p>These terms and conditions outline the rules and regulations for the use of the Nom Nom Map
                        website.</p>
                    
                    <p>By accessing this website, we assume you accept these terms and conditions in full. Do not
                        continue
                        to
                        use Nom Nom Map's website if you do not accept all of the terms and conditions stated on this
                        page.</p>

                    <p>The following terminology applies this Terms and Conditions notice and any or all Agreements:
                        "Client," "You," and "Your" refer to you, the person accessing this
                        website
                        and accepting the Company's terms and conditions. "The Company," "Ourselves," "We," "Our," and
                        "Us,"
                        refer to Nom Nom Map. "Party," "Parties," or "Us," refer to both the Client and ourselves, or
                        either
                        the
                        Client or ourselves. All terms refer to the offer, acceptance, and consideration of payment
                        necessary to
                        undertake the process of our assistance to the Client in the most appropriate manner, whether by
                        formal
                        meetings of a fixed duration or any other means, for the express purpose of meeting the Client's
                        needs
                        in respect of the provision of the Company's stated services/products, in accordance with and
                        subject
                        to, prevailing law of Australia.</p>

                    <p>Nom Nom Map is owned and developed by <a href={"https://mogumedia.com"}>Mogu Media</a></p>
                </div>

                <h2 className="font-bold">Cookies</h2>
                <div className="space-y-4 mb-4">
                    <p>We employ the use of cookies. By accessing Nom Nom Map's website, you agree to use cookies in
                        agreement
                        with Nom Nom Map's Privacy Policy.</p>
                </div>

                <h2 className="font-bold">License</h2>
                <div className="space-y-4 mb-4">
                    <p>Unless otherwise stated, Nom Nom Map and/or its licensors own the intellectual property rights
                        for
                        all
                        material on Nom Nom Map. All intellectual property rights are reserved. You may access this from
                        Nom
                        Nom
                        Map for your own personal use subjected to restrictions set in these terms and conditions.
                    </p>
                    <p>Embedded videos remain the intellectual property of their respective producers.</p>
                </div>

                <h2 className="font-bold">You must not:</h2>
                <div className="space-y-4 mb-4">
                    <ul className="list-disc list-inside ml-4">
                        <li>Republish material from Nom Nom Map except through sharing of links, unless otherwise agreed in writing</li>
                        <li>Sell, rent, or sub-license material from Nom Nom Map</li>
                        <li>Reproduce, duplicate, or copy material from Nom Nom Map</li>
                        <li>Redistribute content from Nom Nom Map</li>
                    </ul>
                </div>

                <h2 className="font-bold">Content Liability</h2>
                <div className="space-y-4 mb-4">
                    <p>We shall not be held responsible for any content that appears on your website. You agree to
                        protect
                        and
                        defend us against all claims that are rising on your website. No link(s) should appear on any
                        website
                        that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise
                        violates,
                        or
                        advocates the infringement or other violation of, any third party rights.</p>
                </div>
                
                <h2 className="font-bold">Content Sourcing</h2>
                <div className="space-y-4 mb-4">
                    <p>Content on Nom Nom Map is sourced from multiple sources and influencers, at the sole discretion
                        of
                        Nom
                        Nom Map. While we strive to ensure accuracy and reliability, we cannot guarantee the
                        authenticity or
                        completeness of all content. Users should exercise their discretion when relying on information
                        provided
                        on Nom Nom Map.</p>
                </div>
                <h2 className="font-bold">Sponsored Content</h2>
                <div className="space-y-4 mb-4">
                    <p>Nom Nom Map may feature sponsored or paid content produced by us other others. While we strive to ensure transparency and
                        maintain
                        editorial integrity, sponsored content may not necessarily represent the views or opinions of
                        Nom
                        Nom
                        Map. Users should exercise their discretion when engaging with sponsored content.</p>
                </div>
                <h2 className="font-bold">Reservation of Rights</h2>
                <div className="space-y-4 mb-4">
                    <p>We reserve the right to request that you remove all links or any particular link to our website.
                        You
                        approve to immediately remove all links to our website upon request. We also reserve the right
                        to
                        amend
                        these terms and conditions and its linking policy at any time. By continuously linking to our
                        website,
                        you agree to be bound to and follow these linking terms and conditions.</p>
                </div>

                <h2 className="font-bold">Disclaimer</h2>
                <div className="space-y-4 mb-4">
                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties,
                        and
                        conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                        <ul className="list-disc list-inside ml-4">
                            <li>limit or exclude our or your liability for death or personal injury;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>limit any of our or your liabilities in any way that is not permitted under applicable
                                law;
                                or
                            </li>
                            <li>exclude any of our or your liabilities that may not be excluded under applicable law.
                            </li>
                        </ul>
                    </p>
                        <p>
                        The limitations and prohibitions of liability set in this Section and elsewhere in this
                        disclaimer:
                            <ul className="ml-4">
                                <li>(a)
                                    are subject to the preceding paragraph; and
                                </li>
                                <li>(b) govern all liabilities arising under the disclaimer, including liabilities
                                    arising in contract, in tort, and for breach of statutory duty.
                                </li>
                            </ul>
                        </p>
                    <p>We are not liable for any loss or damage of any nature associated with your use of the website or the information conveyed within.</p>
                </div>

                <h2 className="font-bold">Governing Law & Jurisdiction</h2>
                <div className="space-y-4 mb-4">
                    <p>These terms will be governed by and interpreted in accordance with the laws of Queensland,
                        Australia,
                        and
                        you submit to the non-exclusive jurisdiction of the courts located in Brisbane, Queensland,
                        Australia
                        for the resolution of any disputes.</p>

                    <p>By using Nom Nom Map's website, you hereby consent to our terms and conditions and agree to its
                        privacy
                        policy. If you have any questions regarding these terms and conditions, please contact us.</p>
                </div>
        </>
    );
}

export default TermsAndConditionsPage;
