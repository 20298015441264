//import { useDispatch, useSelector } from "react-redux";
//import "@heroicons/react/24/outline";

//import { appActions } from "../store";

export default function Header() {
    //const dispatch = useDispatch();
    //const categories = useSelector((state) => state.categories);

    // const onCategoryChanged = (el) => {
    //   const categoryId = el.target.value;
    //   dispatch(appActions.setCategory(categoryId));
    // };

    return (
        <div className="absolute z-50">
            <div className="relative top-3 left-4 w-64">
                <div className="flex">
                    {/* <div className="relative ml-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
            </div>
            <select
              id="category"
              className="block rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:w-full focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              onChange={onCategoryChanged}
            >
              <option value="">(All food types)</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div> */}
                </div>
            </div>
        </div>
    );
}
