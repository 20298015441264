import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faHeart, faListUl, faLocationDot, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import React, {useState} from "react";

interface SidebarMenuProps {
    isOpen: boolean
    onCloseClicked: Function
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({...props}) => {

    const onCloseClicked = props.onCloseClicked;

    return (
        <>
            {props.isOpen && (
                <div className="fixed z-[9999999] bg-black/50 h-dvh w-dvw">&nbsp;</div>
            )}

            {/* This approach keeps it off-screen but visible for google indexing */}
            <nav
                className={`z-[9999999] transition-all ease-in-out duration-200 fixed inset-0 h-dvh w-72 bg-white border-r border-gray-400 px-5 text-gray-800
                   flex flex-col overflow-y-auto text-sm
                   ${props.isOpen ? "translate-x-0" : "-translate-x-full"}`}>
                <div className="flex items-center border-b py-4 border-gray-200">
                    <img src="/favicon-32x32.png" alt="Nom Nom Map logo" className="pr-3"/>
                    <h1>Nom Nom Map</h1>
                    <button type="button" className="ml-auto" onClick={() => onCloseClicked()}><FontAwesomeIcon
                        icon={faClose} className="text-2xl"/></button>
                </div>
                <ul className="pt-2 space-y-1">
                    <li>
                        <Link onClick={() => onCloseClicked()} to="/" className="py-2 block group hover:text-blue-600">
                            <FontAwesomeIcon icon={faLocationDot} className="w-6 mr-2 text-xl"/>Map
                        </Link>
                    </li>
                    <li>
                        {/*TODO: implement favourites*/}
                        <div className="py-2 block text-gray-300">
                            <FontAwesomeIcon icon={faHeart} className="w-6 mr-2 text-xl"/>Favourites
                        </div>
                    </li>
                </ul>
                {/*TODO: implement guides*/}
                <h2 className="mt-3 pt-3 border-t block">Guides</h2>
                <ul className="pt-2 space-y-1 flex-grow">
                    {/*<li>*/}
                    {/*    <Link to="/guides/in" className="py-2 block group hover:text-blue-600">*/}
                    {/*        <FontAwesomeIcon icon={faListUl} className="w-6 mr-2 text-xl"/>Suburb*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/guides/category" className="py-2 block group hover:text-blue-600">*/}
                    {/*        <FontAwesomeIcon icon={faListUl} className="w-6 mr-2 text-xl"/>Category*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/guides/occasion" className="py-2 block hover:text-blue-600">*/}
                    {/*        <FontAwesomeIcon icon={faListUl} className="w-6 mr-2 text-xl text-gray-700"/>Occasion*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li><p className="ml-3 text-sm rounded-lg px-2 bg-blue-300 inline-block">Coming soon!</p></li>
                </ul>
                
                <ul className="pt-2 space-y-1">
                    <li>
                        <a href="https://mogumedia.com/get-on-nomnommap/"
                           target="_blank"
                           className="mb-4 block font-bold group hover:text-blue-600">
                            <FontAwesomeIcon icon={faPlus} className="w-6 mr-2 text-xl"/>Add your business
                        </a>
                    </li>
                    <li>
                        <Link to="/about/privacy"
                           className="border-t pt-4 mb-4 block group hover:text-blue-600 text-xs">
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link to="/about/terms"
                           className="mb-4 block group hover:text-blue-600 text-xs">
                            Terms & Conditions
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default SidebarMenu;