import React from "react";
import {useEffect} from "react";
import ReactGA from "react-ga4";

function PrivacyPolicyPage() {

    useEffect(() => {
        document.title = "Nom Nom Map Privacy Policy";

        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search
        });
    }, []);

    return (
        <div>
            <h1 className="font-bold text-lg mb-4">Privacy Policy for Nom Nom Map</h1>
            <div className="space-y-4 mb-4">
                <p>At Nom Nom Map, accessible at nomnommap.com, one of our main priorities is the privacy of our
                    visitors.
                    This Privacy Policy document contains types of information that is collected and recorded by Nom Nom
                    Map
                    and how we use it.</p>
                <p>If you have additional questions or require more information about our Privacy Policy, do not
                    hesitate to
                    contact us through email at contact@mogumedia.com</p>
                <p>This privacy policy applies only to our online activities and is valid for visitors to our website
                    with
                    regards to the information that they shared and/or collect in Nom Nom Map. This policy is not
                    applicable
                    to any information collected offline or via channels other than this website.</p>
            </div>
            <h2 className="font-bold">Consent</h2>
            <div className="space-y-4 mb-4">
                <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
            </div>
            <h2 className="font-bold">Information we collect</h2>
            <div className="space-y-4 mb-4">
                <p>The personal information that you are asked to provide, and the reasons why you are asked to provide
                    it,
                    will be made clear to you at the point we ask you to provide your personal information.</p>

                <p>If you contact us directly, we may receive additional information about you such as your name, email
                    address, phone number, the contents of the message and/or attachments you may send us, and any other
                    information you may choose to provide.</p>

                <p>If you register for an Account, we may ask for your contact information, including items such as
                    name,
                    company name, address, email address and other information we deem necessary for our purposes.</p>
            </div>
            <h2 className="font-bold">How we use your information</h2>
            <div className="space-y-4 mb-4">
                <p>We use the information we collect in various ways, including to:</p>
                <ul className="list-disc list-inside ml-4">
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalise, and expand our website</li>
                    <li>Understand and analyse how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you, either directly or through one of our partners, including for customer
                        service, to provide you with updates and other information relating to the website, and for
                        marketing and promotional purposes
                    </li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                    <li>Log Files</li>
                </ul>

                <p>Nom Nom Map follows a standard procedure of using log files and usage analytics. This includes
                    logging of
                    visitor information when they visit and interact with the website. The information collected in log
                    files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date
                    and
                    time stamp, referring/exit pages, actions taken on the website including clicks, views and
                    interactions.
                    The purpose of collecting this information is for analysing trends, administering the site,
                    identifying
                    problems, tracking users' movement on the website, and gathering demographic information.</p>
            </div>
            <h2 className="font-bold">Cookies and Web Beacons</h2>
            <div className="space-y-4 mb-4">
                <p>Nom Nom Map uses cookies to store information including visitors' preferences and to link to you the
                    pages on the website that were accessed or visited. The information is used to optimise the user
                    experience by customising our web page content based on visitors' browser type and/or other
                    information
                    and for analytical purposes.</p>

            </div>
            <h2 className="font-bold">Analytics & Trackers</h2>
            <div className="space-y-4 mb-4">
                <p>Nom Nom Map utilises third-party analytics and trackers such as Google Analytics to enhance your
                    experience and analyse your usage on this website.</p>

                <p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed
                    below. Each of our advertising partners has their own Privacy Policy for their policies on user
                    data.
                    For easier access, we have listed their Privacy Policies below.</p>

                <ul className="list-disc list-inside ml-4">
                    <li>Google, https://policies.google.com/technologies/ads</li>
                </ul>
            </div>
            <h2 className="font-bold">Advertising Partners Privacy Policies</h2>
            <div className="space-y-4 mb-4">
                <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that
                    are
                    used in their respective advertisements and links that appear on Nom Nom Map, which are sent
                    directly to
                    users' browser. They automatically receive your IP address and other information when this occurs.
                    These
                    technologies are used to measure the effectiveness of their advertising campaigns and/or to
                    personalise
                    the advertising content that you see on websites that you visit.</p>

                <p>Note that Nom Nom Map has no access to or control over these cookies that are used by third-party
                    advertisers.</p>
            </div>
            <h2 className="font-bold">Third-Party Privacy Policies</h2>
            <div className="space-y-4 mb-4">
                <p>Nom Nom Map's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising
                    you
                    to consult the respective Privacy Policies of these third-party ad servers for more detailed
                    information. It may include their practices and instructions about how to opt-out of certain
                    options.
                    You may find a complete list of these Privacy Policies at the respective service provider's
                    websites.</p>

                <p>You can choose to disable cookies through your individual browser options.</p>
            </div>
            <h2 className="font-bold">GDPR Privacy Policy (Data Protection Rights)</h2>
            <div className="space-y-4 mb-4">
                <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is
                    entitled to the following:</p>

                <ul className="list-disc list-inside ml-4">
                    <li>The right to access – You have the right to request copies of your personal data. We may charge
                        you
                        a small fee for this service.
                    </li>
                    <li>The right to rectification – You have the right to request that we correct any information you
                        believe is inaccurate. You also have the right to request that we complete the information you
                        believe is incomplete.
                    </li>
                    <li>The right to erasure – You have the right to request that we erase your personal data, under
                        certain
                        conditions.
                    </li>
                    <li>The right to restrict processing – You have the right to request that we restrict the processing
                        of
                        your personal data, under certain conditions.
                    </li>
                    <li>The right to object to processing – You have the right to object to our processing of your
                        personal
                        data, under certain conditions.
                    </li>
                    <li>The right to data portability – You have the right to request that we transfer the data that we
                        have
                        collected to another organisation, or directly to you, under certain conditions.
                    </li>
                </ul>

                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
                    these
                    rights, please contact us.</p>
            </div>
            <h2 className="font-bold">Children's Information</h2>
            <div className="space-y-4 mb-4">
                <p>Another part of our priority is adding protection for children while using the internet. We encourage
                    parents and guardians to observe, participate in, and/or monitor and guide their online
                    activity.</p>

                <p>Nom Nom Map does not knowingly collect any Personal Identifiable Information from children under the
                    age
                    of 13. If you think that your child provided this kind of information on our website, we strongly
                    encourage you to contact us immediately and we will do our best efforts to promptly remove such
                    information from our records.</p>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
