import React, {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {faCaretDown, faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {appActions} from "../store/index";
import LoadingSpinner from "./LoadingSpinner";
import LocationSearchDialog from "./LocationSearchDialog";
import CategorySearchDialog from "./CategorySearchDialog";
import ReactGA from "react-ga4";
import AppConfig from "../store/config";

export default function SearchControl(props) {
    const dispatch = useDispatch();
    
    const onUseCurrentLocation = props.onUseCurrentLocation;
    const onOpenSidebarMenu = props.onOpenSidebarMenu;
    
    const [showCategoryDialog, setShowCategoryDialog] = useState(false);
    const [showLocationSearchDialog, setShowLocationSearchDialog] = useState(false);
    const categoryId = useSelector((state) => state.categoryId);
    const categoryName = useSelector((state) => state.categoryName);
    const isLoading = useSelector((state) => state.isLoading);
    const selectedLocation = useSelector((state) => state.selectedLocation);

    const locationSelected = (id, name, lat, lng) => {
        ReactGA.event("filter", {
            filter_type: "location",
            filter_event_origin: "search_control",
            item_id: id,
            item_name: name
        });

        setShowLocationSearchDialog(false);
        dispatch(appActions.setLocation({id: id, name: name, lat: lat, lng: lng}));
    };

    const currentLocationSelected = () => {
        ReactGA.event("filter", {
            filter_type: "location",
            filter_event_origin: "search_control",
            item_id: AppConfig.CurrentLocationId,
            item_name: AppConfig.CurrentLocationName,
        });

        setShowLocationSearchDialog(false);
        
        if (onUseCurrentLocation)
            onUseCurrentLocation();        
    };

    return (
        <>
            {showCategoryDialog &&
                (<CategorySearchDialog
                    showDialog={showCategoryDialog}
                    onClose={() => {
                        setShowCategoryDialog(false);
                    }}
                />)
            }
            {showLocationSearchDialog && (
                <LocationSearchDialog onLocationSelected={locationSelected}
                                      onCurrentLocationSelected={currentLocationSelected}
                                      onCancel={() => setShowLocationSearchDialog(false)}/>
            )
            }
            <div
                className="flex rounded-3xl border border-gray-300 bg-white text-sm text-gray-900 shadow-lg items-center">
                <div className="ml-3 w-8">
                    {isLoading && <LoadingSpinner/>}
                    {!isLoading && (
                        <button type="button" onClick={onOpenSidebarMenu} className="p-2">
                            <FontAwesomeIcon icon={faBars} size="lg" />
                        </button>
                    )}
                </div>
                <button
                    type="button"
                    className="text-nowrap justify-center gap-x-1.5 pl-3 pr-2 text-sm text-gray-900 rounded-l-3xl h-full"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => setShowCategoryDialog(true)}
                >
                    {categoryId === null ? "All foods" : categoryName}
                    <FontAwesomeIcon icon={faCaretDown} className="pl-1"/>
                </button>
                <p className="mx-2">in</p>
                <button
                    type="button"
                    className="max-w-40 text-nowrap truncate justify-center gap-x-1.5 text-sm text-gray-900 rounded-r-3xl h-full pr-4"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => setShowLocationSearchDialog(true)}
                >
                    {selectedLocation?.name ?? "Brisbane CBD"}
                </button>
            </div>
        </>
    );
}
