import Header from "../components/Header";
import Map from "../components/Map";
import {useEffect} from "react";
import ReactGA from "react-ga4";

function HomePage() {
        
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search
        });
    }, []);

    return (
        <div className="flex h-dvh flex-col">
            <Header/>
            <Map/>
        </div>
    );
}

export default HomePage;
