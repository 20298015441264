import store from "../store/index";
import {Provider} from "react-redux";
import {Outlet} from "react-router-dom";

export default function Root() {
    return (
        <Provider store={store}>
            <Outlet/>
        </Provider>
    );
}