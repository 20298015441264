/* eslint-disable */
import { Dialog } from "@headlessui/react";
import React from 'react';
import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface DialogWithOverlayProps {
  title?: string,
  fullscreenOnMobile?: boolean,
  closeButtonStyle: "None" | "TopRight" | "BelowContent",
  onClose: Function,
  children: ReactNode
}

const DialogWithOverlay: React.FC<DialogWithOverlayProps> = ({ ...props }) => {
  const fullscreenAtMobileSize = props.fullscreenOnMobile ?? false;
  
  return (
    <Dialog open={true} onClose={() => props.onClose()} className="relative z-50">
      <div className="fixed inset-0 bg-black/60" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      {/*<div className="fixed inset-0 flex w-screen items-center justify-center p-4">*/}
      {/*overflow-y-scroll*/}
      <div className="fixed inset-0">
        <div className={`flex ${fullscreenAtMobileSize ? "min-h-full sm:items-center sm:justify-center":"fixed inset-0 w-screen items-center justify-center"}`}>
          {/*className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">*/}
          <Dialog.Panel
            className={`p-4 w-full ${fullscreenAtMobileSize ? "sm:w-[32rem] sm:h-4/6 sm:rounded-2xl" : "max-w-md rounded-2xl"} mx-auto bg-white p-3 text-left align-middle shadow-xl`}>
            {props.title && (
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                <div className="flex flex-row">
                  <h1 className="flex-grow">
                    <span className="font-bold">{props.title}</span>
                  </h1>
                  {props.closeButtonStyle == "TopRight" &&
                    <a onClick={() => props.onClose()}>
                      <FontAwesomeIcon size="xl" icon={faClose} />
                    </a>
                  }
                </div>
              </Dialog.Title>)
            }
            {props.children}
            {props.closeButtonStyle == "BelowContent" &&
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={() => props.onClose()}
                >
                  Close
                </button>
              </div>
            }
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogWithOverlay;



