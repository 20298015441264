import DialogWithOverlay from "./DialogWithOverlay";
import {Combobox} from "@headlessui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faLocationPin, faLocationCrosshairs} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from 'react';
import LoadingSpinner from "./LoadingSpinner";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import AppConfig from "../store/config";

interface LocationSearchDialogProps {
    onLocationSelected: Function;
    onCurrentLocationSelected: Function;
    onCancel: Function;
}

const LocationSearchDialog: React.FC<LocationSearchDialogProps> = ({...props}) => {
    const {
        ready,
        value,
        suggestions: {status, data, loading},
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            locationBias: "IP_BIAS", // doesn't seem to actually work..
            componentRestrictions: {
                country: "au"
            }
        },
        debounce: 500,
        cache: 24 * 60 * 60, // cache results for 1 day
    });

    const [comboOptions, setComboOptions] = useState<any>(null);

    useEffect(() => {
        if (!value) {
            setComboOptions([
                {
                    place_id: AppConfig.CurrentLocationId,
                    description: AppConfig.CurrentLocationName
                }
            ]);
        } else {
            setComboOptions([...data]);
        }
    }, [value, data]);

    const locationSelected = (selected: any) => {
        if (!selected)
            return;

        console.log("locationSelected: " + selected.description);

        if (selected.place_id === AppConfig.CurrentLocationId) {
            if (props.onCurrentLocationSelected) {
                props.onCurrentLocationSelected();
            }
        } else {
            getGeocode({address: selected.description}).then((results) => {
                const {lat, lng} = getLatLng(results[0]);
                if (props.onLocationSelected) {
                    props.onLocationSelected(selected.place_id, selected.description, lat, lng);
                }
            });
        }
    };

    const cancelled = () => {
        props.onCancel();
    };

    return (
        <DialogWithOverlay onClose={() => {
        }}
                           closeButtonStyle="None"
                           fullscreenOnMobile={true}>
            <Combobox
                as="div"
                className="flex flex-col bg-white h-full min-h-96 sm:max-h-96"
                defaultValue={(searchRes: { label: any; }) => searchRes?.label}
                onChange={locationSelected}
            >
                {/*todo: clear button, 'back' button*/}
                <div className="flex align-middle items-center w-full border rounded-2xl mb-2">
                    <button
                        type="button"
                        className="px-3 border-r h-full rounded-l-2xl"
                        aria-expanded="true"
                        aria-haspopup="true"
                        onClick={cancelled}
                        tabIndex={2}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="pl-1"/>
                    </button>
                    <Combobox.Input
                        className="p-2 w-full rounded-2xl ring-0 border-none focus:outline-none"
                        tabIndex={1}
                        placeholder="Search location.."
                        name="search"
                        autoCorrect="false"
                        autoCapitalize="false"
                        onChange={(event) => setValue(event.target.value, true)}
                    />
                </div>
                <Combobox.Options className="text-sm h-full overflow-y-auto" static>
                    {loading && (<div className="py-3"><LoadingSpinner/></div>)}
                    {ready && !loading && comboOptions && comboOptions.map((res: any) => (
                        <Combobox.Option key={res.place_id} value={res}>
                            {({active}) => (
                                <div
                                    className={`cursor-pointer flex flex-row items-center h-14 px-1 py-2 border-b ${
                                        active ? "bg-indigo-600" : "bg-white"
                                    }`}
                                >
                                    <FontAwesomeIcon size="xl"
                                                     icon={res.place_id === AppConfig.CurrentLocationId ? faLocationCrosshairs : faLocationPin}/>
                                    <span
                                        className={`pl-3 font-medium text-gray-900 ${
                                            active ? "text-white" : "text-gray-900"
                                        }`}
                                    >
                {res.description}
              </span>
                                </div>
                            )}
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </Combobox>
        </DialogWithOverlay>
    );
};

export default LocationSearchDialog;
