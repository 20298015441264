import store from "../store/index";
import {Provider} from "react-redux";
import {Outlet} from "react-router-dom";
import TopNavMenu from "../components/TopNavMenu";
import Footer from "../components/Footer";

export default function ContentPageRoot() {
    return (
        <Provider store={store}>
            <TopNavMenu/>
            <div className="px-4">
                <Outlet/>
            </div>
            <Footer/>
        </Provider>
    );
}