import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faHeart, faListUl, faLocationDot, faMap, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import React, {useState} from "react";

interface TopNavMenuProps {
}

const TopNavMenu: React.FC<TopNavMenuProps> = ({...props}) => {

    return (
        <nav className="flex p-4 items-center bg-white mb-4 border-b border-gray-300">
            <div className="flex items-center text-nowrap">
                <img src="/favicon-32x32.png" alt="Nom Nom Map logo" className="pr-2"/>
                Nom Nom Map
            </div>
            {/*TODO: active link styling*/}
            <ul className="flex pl-10 flex-grow flex-nowrap overflow-clip items-center">
                <li><Link to="/"
                          className="text-nowrap hover:text-amber-600 hover:border-b-2 hover:border-amber-600 py-2 mr-8">
                    <FontAwesomeIcon icon={faMap} className="w-6 mr-2"/>Map
                </Link></li>
                <li className="text-nowrap text-gray-400 py-2 mr-8">
                    <FontAwesomeIcon icon={faHeart} className="w-6 mr-2"/>Favourites
                </li>
                {/*<li><Link to="/guides" className="text-nowrap hover:text-amber-600 hover:border-b-2 border-amber-600 py-2 mr-8">*/}
                {/*    <FontAwesomeIcon icon={faListUl} className="w-6 mr-2"/>Guides</Link>*/}
                {/*</li>*/}
                {/*<li><Link to="/guides/in"*/}
                {/*          className="text-nowrap hover:text-amber-600 hover:border-b-2 border-amber-600 py-2 mr-8">Suburb</Link>*/}
                {/*</li>*/}
                {/*<li><Link to="/guides/category"*/}
                {/*          className="text-nowrap hover:text-amber-600 hover:border-b-2 border-amber-600 py-2 mr-8">Category</Link>*/}
                {/*</li>*/}
                {/*<li><Link to="/guides/occasion"*/}
                {/*          className="text-nowrap hover:text-amber-600 hover:border-b-2 border-amber-600 py-2 mr-8">Occasion</Link>*/}
                {/*</li>*/}
                <li className="ml-auto text-nowrap"><a href="https://mogumedia.com/get-on-nomnommap"
                                                       className="border rounded-lg hover:text-amber-600 hover:border-b-2 border-amber-600 py-2 px-2"
                                                       target="_blank">
                    <FontAwesomeIcon icon={faPlus} className="w-6 mr-2 text-xl"/>Add my business</a></li>
            </ul>
        </nav>
    )
}

export default TopNavMenu;